import { Injectable } from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class IconService {
  private iconsList: { iconName: string; path: string }[] = [
    { iconName: 'plane', path: 'assets/svg/plane.svg' },
    { iconName: 'heart', path: 'assets/svg/heart.svg' },
    { iconName: 'earth', path: 'assets/svg/earth.svg' },
    { iconName: 'cerf', path: 'assets/svg/cerf.svg' },
    { iconName: 'canada', path: 'assets/svg/canada.svg' },
    { iconName: 'quebec', path: 'assets/svg/quebec.svg' },
    { iconName: 'france', path: 'assets/svg/france.svg' },
    { iconName: 'line', path: 'assets/svg/line.svg' },
  ];

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer,
  ) {}

  public registerSvgIcons() {
    this.iconsList.forEach((icon) => {
      this.iconRegistry.addSvgIcon(
        icon.iconName,
        this.sanitizer.bypassSecurityTrustResourceUrl(icon.path),
      );
    });
  }
}
